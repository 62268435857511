/*************** banner-slider **************/
 jQuery(document).ready(function() {

    // Menu Responsive
     jQuery(".mobile-icon a").on("click touchstart", function (event) {
        event.preventDefault();
        event.stopPropagation();
        jQuery("body").toggleClass("open-menu");
    });

    jQuery(document).on('click', function () {
        jQuery('body').removeClass('open-menu');
    });

     jQuery(".site-header .header-right").on("click touchstart", function (event) {
        event.stopPropagation();
    });

    jQuery(".touch .main-navigation ul li > a").on("click touchstart",function(event){

       if(!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0)
       {
        event.preventDefault();
        event.stopPropagation();
        jQuery(this).parent().addClass("open").siblings().removeClass("open");
       }
      });

    // Banner Slider

    jQuery('.banner-slider .owl-carousel').owlCarousel({
        items:1,
        autoplay: true,
        loop: true,
        dots: true,

    });

});
